import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Link, navigate } from "gatsby"

import BasicSlider from "../components/BasicSlider"

import fetchJsonp from "fetch-jsonp"

require("es6-promise").polyfill()

export default function HomepagePagePage({
  pageContext: { integratorData, contentMetaData, contentDataData },
  props,
}) {
  const [isIinitialLoad, setIsIinitialLoad] = useState(true)

  const [categoryFilter, setCategoryFilter] = useState([])
  const [jobTypeFilter, setJobTypeFilter] = useState([])
  const [locationFilter, setLocationFilter] = useState([])

  const [locationsValue, setLocationsValue] = useState("")
  const [jobtypeValue, setJobtypeValue] = useState("")
  const [categoryValue, setCategoryValue] = useState("")

  function removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b)
  }

  const apiKey = "KveXyBB8IM6CDLBqJxwd16Qd6u7LWisyQeg1buu3SrQ"

  const fetchFilters = async (name, setState, label) => {
    try {
      fetchJsonp(
        "https://sf.vacancy-filler.co.uk/CareerPage/" +
          name +
          "?" +
          "id" +
          "=" +
          apiKey +
          "&region=" +
          "all"
      )
        .then(function(response) {
          return response.json()
        })
        .then(function(jsonList) {
          // let temp = ["All " + label]
          let temp = ["Select a " + label]

          setState(temp.concat(removeDuplicates(jsonList)))
        })
        .catch(function(ex) {
          console.error(ex, "error")
        })
    } catch (error) {
      console.error(error.message, "error")
    }
  }

  useEffect(() => {
    if (isIinitialLoad) {
      setIsIinitialLoad(false)

      const timeOut = setTimeout(() => {
        fetchFilters("GetLocations", setLocationFilter, "location") // Locations
        fetchFilters("GetCategories", setCategoryFilter, "category") // Categories
        fetchFilters("GetJobTypes", setJobTypeFilter, "job type") // Job Types
      }, 50)
      return () => clearTimeout(timeOut)
    }
  }, [])

  const searchFormSubmit = data => {
    data.preventDefault()

    navigate(
      `/devonshire-group/vacancies?location=${locationsValue}&jobtype=${jobtypeValue}&category=${categoryValue}`
    )
  }

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{contentMetaData.seoTitle}</title>
        <meta name="description" content={contentMetaData.metaDescription} />
      </Helmet>

      <BasicSlider
        onBeforeChangeFunction={(type, previous, next) => {
          return null
        }}
        shouldDisplayButtons={false}
        pauseOnHover={false}
        pauseOnFocus={false}
        adaptiveHeight={true}
        slides={contentDataData[0].children[0].children}
      />
      <div className="home">
        <div className="container">
          <div className="page-main-container-wrap">
            <div className="row">
              <div className="col-md-12">
                <div className="searchBlock">
                  <div className="VF_filter_form">
                    <h1>Join the Devonshire Group</h1>
                    <div className="row headers">
                      <div>
                        <span>
                          <a href="/devonshire-group/vacancies/">Jobs</a>
                        </span>{" "}
                        <span className="link-break">|</span>
                        <span>
                          <a href="/devonshire-group/volunteering/">
                            Volunteering
                          </a>
                        </span>{" "}
                        <span className="link-break">|</span>{" "}
                        <span>
                          <a href="/devonshire-group/work-experience/">
                            Apprenticeships, Work Experience &amp; Placements
                          </a>
                        </span>
                      </div>
                    </div>
                    <div
                      id="CustomDesign"
                      className="inputs-number search-form-home"
                    >
                      <form
                        className="homepage-search-form row"
                        onSubmit={data => searchFormSubmit(data)}
                      >
                        <div className="col-lg-3 col-md-12 xs">
                          <div className="dropdown">
                            <select
                              className="form-control"
                              name="category"
                              id="select-category"
                              onChange={e =>
                                setCategoryValue(e.currentTarget.value)
                              }
                            >
                              {categoryFilter.map((item, i) => (
                                <option key={i} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12 xs">
                          <div className="dropdown">
                            <select
                              className="form-control"
                              name="jobtype"
                              id="select-jobtype"
                              onChange={e =>
                                setJobtypeValue(e.currentTarget.value)
                              }
                            >
                              {jobTypeFilter.map((item, i) => (
                                <option key={i} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12 xs">
                          <div className="dropdown">
                            <select
                              className="form-control"
                              name="location"
                              id="select-location"
                              onChange={e =>
                                setLocationsValue(e.currentTarget.value)
                              }
                            >
                              {locationFilter.map((item, i) => (
                                <option key={i} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col">
                          <button className="search-form-home-button">
                            <span>Search</span>{" "}
                            <i className="arrow right icon"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="homepage-section-cards">
              <div className="row">
                {contentDataData[1].children[0].children.map((column, i) => (
                  <div
                    className="col-lg-4 col-md-6 col-12 homepage-section-card"
                    key={i}
                  >
                    <div className="top-card-section">
                      <a
                        href={
                          contentDataData[1].children[0].children[i][3].value
                        }
                        className="top-card-float-link"
                      ></a>
                      <div className="top-card-content">
                        <div className="image">
                          <div
                            className="background-card-img"
                            style={{
                              backgroundImage:
                                "url(" +
                                contentDataData[1].children[0].children[i][0]
                                  .value +
                                ")",
                            }}
                          ></div>
                        </div>
                        <div className="content">
                          <div className="content-inner">
                            <h2>
                              {
                                contentDataData[1].children[0].children[i][1]
                                  .value
                              }
                            </h2>
                            <h3>
                              {
                                contentDataData[1].children[0].children[i][2]
                                  .value
                              }
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-card-section">
                      <a
                        href={
                          contentDataData[1].children[0].children[i][3].value +
                          "vacancies"
                        }
                        className="btn btn-two"
                      >
                        View Vacancies
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="full-width-card col-md-12 homepage-section-card">
                  <div className="top-card-section">
                    <a
                      href="/devonshire-group/"
                      className="top-card-float-link"
                    ></a>
                    <div className="top-card-content">
                      <div className="row">
                        <div className="image col-sm-12 col-md-6">
                          <img
                            src={contentDataData[2].children[0].value}
                            alt="Devonshire Group"
                          />
                        </div>
                        <div className="content col-sm-12 col-md-6">
                          <div className="content-inner">
                            <div className="bottom-card-section">
                              <img
                                src={contentDataData[2].children[1].value}
                                alt="Duke and Duchess"
                              />
                              <h2>{contentDataData[2].children[2].value}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="top">
        <div className="container" style={{ margin: "0px auto" }}>
          <div id="job-alerts" className="row alerts">
            <div className="col-md-12 col-12">
              <div className="job-alert-wrap">
                <span>Keep up to date with the latest jobs from us</span>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="button-wrap">
                <Link
                  to="/job-alerts"
                  type="button"
                  className="btn btn-primary btn-jobalerts"
                >
                  SUBSCRIBE TO JOB ALERTS{"   "}
                  <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
